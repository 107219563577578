/* Add your @font-face setting the url path */
@font-face {
  font-family: "Merriweather-Light";
  src: url("fonts/Merriweather-Light.ttf") format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Merriweather";
  src: url("fonts/Merriweather-Regular.ttf") format('truetype');
  font-display: swap;
}

/*
Set the sass variables. You only need the $base-font-family here but in case you want to adjust sizes etc.
For reference, in the Minima theme gem folder, these are in /_sass/minima.scss
*/
$base-font-family: "Merriweather-Light" !default;
$base-font-size: 16px !default;
$base-line-height: 1.9 !default;
$base-font-weight: 300 !default;

$lm-text-color: #111 !default;
$lm-brand-color: #666 !default;
$lm-table-header-bg-color: lighten($lm-brand-color, 50%) !default;
$lm-table-zebra-color:     lighten($lm-brand-color, 57%) !default;

$dm-text-color: #ccc !default;
$dm-brand-color: #ddd !default;
$dm-table-header-bg-color: darken($dm-brand-color, 65%) !default;
$dm-table-zebra-color:     darken($dm-brand-color, 73%) !default;
$dm-table-header-border:   darken($dm-brand-color, 50%) !default;
$dm-table-border-color:    darken($dm-brand-color, 50%) !default;
$dm-border-color-01:       darken($dm-brand-color, 50%) !default;

$content-width:    800px !default;
$sidebar-width:    240px !default;

// animation is causing problem in firefox
// body * {
//   transition: color .3s ease-in-out;
//   transition: background-color .3s ease-in-out;
// }

// body {
//   transition: color .3s ease-in-out;
//   transition: background-color .3s ease-in-out;
// }

@import
  "minima/skins/local",
  "minima/initialize";


.site-header {
  border: none;
  padding: $spacing-unit 0;
}
.site-title-desc-wrapper {
  float: left;
  margin-bottom: 0;
}

.site-title {
  float: none;
  color: $brand-color-dark;
}

.site-description {
  font-size: 13px;
  color: $brand-color;
  margin-top: -36px;
  float: left;
}

.post-list {
  > li {
    margin-bottom: $spacing-unit * 2;
  }
}

.post-link {
  font-family: "Merriweather";
  font-size: 28px;
  color: $text-color;

  &:visited {
    color: $text-color;
  }
}

.post-nav {
  border-top: 1px solid $border-color-01;
  padding: 32px 0;
}

.post-nav-wrapper {
  width: 49%;
}

.post-nav-title {
  color: $brand-color;
  font-size: 14px;
}

.post-nav-prev {
  float: left;
}

.post-nav-next {
  float: right;
  text-align: right;
}

.pagination {
  border-top: 1px solid $border-color-01;
  font-size: 18px;
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.prev-next {
  padding-bottom: 16px;
}

.page-number {
  width: 24px;
  padding: 0 8px;
}

input, select {
  border: 1px solid $border-color-01;
  background-color: $background-color;
  color: $text-color;
  min-height: 40px;
  font-family: "Merriweather-Light";
  font-size: 16px;
}

.button, button {
  border: transparent;
  color: $background-color;
  background-color: $text-color;
  font-family: "Merriweather";
  font-weight: 700;
  padding: 12px 16px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.center-img {
  text-align: center;
}

figcaption {
  font-style: italic;
  color: $brand-color;
  font-size: 14px;
  padding: 8px 0;
}

pre,
code {
  font-size: 13px;
  background-color: $table-zebra-color;
}

h2 {
  line-height: 1.5;
}


.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

blockquote {
  cite:before {
    content: "\2014 \00a0 \00a0";
  }

  cite {
    @include relative-font-size(0.9);
  }
}

.full-content {
  display: flex;
}

.main-content {
  flex-direction: column;
  width: 100%;
  max-width: $content-width - $sidebar-width;
}

.sidebar {
  padding-left: $spacing-unit * 2;
  min-width: $sidebar-width;
}

.heading {
  font-weight: 700;
}

@include media-query($on-laptop) {
  .full-content {
    flex-direction: column;
  }

  .sidebar {
    padding-left: 0;
    width: 100%;
  }
}

.icon {
  width: 24px;
  height: 16px;
  margin-bottom: -3px;
}

.post-category, .post-tag {
  padding: 4px 8px;
  line-height: 2.3;
  background: $table-header-bg-color;
  border-radius: 4px;
  color: $text-color;
  margin: 0 2px;

  &:visited {
    color: $text-color;
  }
}

.post-tags-section {
  padding: 8px 0;
}

.sidebar-section {
  margin-bottom: $spacing-unit * 2;
}

.related-posts {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit * 2;
}

.related-post {
  display: flex;
}

.search-box {
  margin-bottom: 32px;
}

.page-content {
  padding: 0 0 30px 0;
}

.post-title {
  line-height: 1.4;
}

.highlight {
  .highlighter-rouge & {
    background-color: $table-zebra-color;
  }
}

.adsbygoogle {
  margin-top: 32px;
}
